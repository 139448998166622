/**
 * Created by vlad on 31/10/18.
 */

HardLevelIcon.prototype.runAnimation = function () {
    this.image.setVisible(false);
    const basePos = this.image.getPosition();
    this.image.setPositionRound(basePos.x, basePos.y + this.image.height);
    this.image.runAction(new cc.Sequence(
        new cc.DelayTime(4),
        new cc.CallFunc(() => {
            this.image.setVisible(true);
        }),
        new cc.MoveTo(0.5, basePos).easing(cc.easeBackOut())
    ));
};

cleverapps.styles.HardLevelIcon = {
    scale: [0.7, 1, 1],
    rotation: 0,
    alignment: [
        { x: { align: "left", dx: 0 }, y: { align: "top", dy: 42 } },
        { x: { align: "left", dx: 30 }, y: { align: "top", dy: 15 } },
        { x: { align: "left", dx: 30 }, y: { align: "top", dy: 15 } }
    ],
    enabled: true,
    parentSceneScene: true
};